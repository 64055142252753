import Vue from 'vue'
import VueRouter from 'vue-router'
const Login = () => import(/* webpackChunkName: "login_home_welome" */ 'components/login/Login')
const Home = () => import(/* webpackChunkName: "login_home_welome" */ 'components/home/Home')
const Welcome = () => import(/* webpackChunkName: "login_home_welome" */ 'components/home/welcome/Welcome')
// 新项目
const userList = () => import(/* webpackChunkName: "UserList" */ 'components/user/list.vue')
const userCenter = () => import(/* webpackChunkName: "UserCenter" */ 'components/user/center.vue')
const offerList = () => import(/* webpackChunkName: "OfferList" */ 'components/offer/list.vue')
const adminOfferList = () => import(/* webpackChunkName: "OfferList" */ 'components/offer/admin-list.vue')
const dashboardHome = () => import(/* webpackChunkName: "DashboardHome" */ 'components/dashboard/home.vue')
const updatePriceCenter = () => import(/* webpackChunkName: "DashboardHome" */ 'components/user/updatePriceCenter.vue')
const shopList = () => import(/* webpackChunkName: "DashboardHome" */ 'components/user/shopList.vue')
const orderList = () => import(/* webpackChunkName: "OrderList" */ 'components/order/orderList.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/home',
    component: Home,
    redirect: '/welcome',
    children: [
      {
        path: '/user-list',
        component: userList
      },
      {
        path: '/offer-list',
        component: adminOfferList
      },
      {
        path: '/user-center-new',
        component: shopList
      },
      {
        path: '/update-price-center',
        component: updatePriceCenter
      },
      {
        path: '/offer-list/:id',
        component: offerList
      },
      {
        path: '/order-list/:id',
        component: orderList
      },
      {
        path: '/welcome',
        component: Welcome
      }
    ]
  }
]
const router = new VueRouter({
  // mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  // to 将访问哪一个路径
  // from 代表从哪个路径跳转而来
  // next 是一个函数,表示放行
  //   next() 放行 next('/login') 强制跳转
  // TODO
  // if (to.path === '/login') return next()
  // let rule = window.localStorage.getItem('rule')
  // if (!rule) return next('/login')
  // // mall-2，admin-1
  // if (to.path.indexOf('admin') > -1 && +rule === 2) {
  //   return next('./welcome')
  // }
  // if (to.path.indexOf('mall') > -1 && +rule === 1) {
  //   return next('./welcome')
  // }
  next()
})

export default router
